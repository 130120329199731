/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

/*
  Import Tailwind's base styles into the browser.
*/
import './src/styles/globals.css';
