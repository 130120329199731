// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-i-18-n-redirect-js": () => import("./../../../src/i18n/redirect.js" /* webpackChunkName: "component---src-i-18-n-redirect-js" */),
  "component---src-pages-corona-virus-information-js": () => import("./../../../src/pages/corona-virus-information.js" /* webpackChunkName: "component---src-pages-corona-virus-information-js" */),
  "component---src-pages-cykelpaket-3-dagar-js": () => import("./../../../src/pages/cykelpaket-3-dagar.js" /* webpackChunkName: "component---src-pages-cykelpaket-3-dagar-js" */),
  "component---src-pages-cykelpaket-js": () => import("./../../../src/pages/cykelpaket.js" /* webpackChunkName: "component---src-pages-cykelpaket-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-vandringspaket-js": () => import("./../../../src/pages/vandringspaket.js" /* webpackChunkName: "component---src-pages-vandringspaket-js" */)
}

